import { endpoints } from 'shared'

import {
  reqDELETE,
  reqDownloadableFile,
  reqFile,
  reqGET,
  reqPOST,
  reqPUT,
} from '@/shared/apis/api-client'
import {
  AttachmentFile,
  FollowUpParams,
  getDashboardParams,
  getNoticeParameter,
  NationCodeParams,
} from '@/types'

export class CommonService {
  getVesselCodeList() {
    return reqGET(endpoints.common.vesselCodes)
  }

  createDocNumber(companyCode: string, vesselCode: string) {
    const params = {
      companyCode: companyCode,
      vesselCode: vesselCode,
      isGeneratedForVessel: false,
    }
    return reqPOST(endpoints.procurement.documentNumber, params)
  }

  getPortList(params: NationCodeParams) {
    return reqGET(endpoints.common.portCodes, params)
  }

  getCommonCodes(groupCodes: Array<string>, query?: string) {
    const params = new URLSearchParams()
    groupCodes.forEach((code) => {
      params.append('gc', code)
    })
    params.append('q', query ?? '')
    return reqGET(endpoints.common.commonCodes, params)
  }

  getFiles(vmsFileId: number) {
    const params = new URLSearchParams()
    params.append('vmsFileId', vmsFileId?.toString())
    return reqGET(endpoints.common.files, params)
  }

  uploadFilesToS3(files: File[], vmsFileId?: number) {
    const formData = new FormData()
    files.forEach((file: File) => {
      formData.append('file', file)
    })
    return reqPOST(
      `${endpoints.common.uploadFileToS3}/multiple`,
      formData,
      vmsFileId ? { vmsFileId } : null,
      {
        'Content-Type': 'multipart/form-data',
      }
    )
  }

  uploadFilesToLocal(files: File[], vmsFileId?: number) {
    const formData = new FormData()
    files.forEach((file: File) => {
      formData.append('file', file)
    })
    return reqPOST(
      `${endpoints.common.uploadFileToLocal}/multiple`,
      formData,
      vmsFileId ? { vmsFileId } : null,
      {
        'Content-Type': 'multipart/form-data',
      }
    )
  }

  deleteFile(vmsFileId: number, fileSequence: number) {
    return reqDELETE(`${endpoints.common.files}/${fileSequence}/references/${vmsFileId}`)
  }

  getRecordTypes() {
    return reqGET(endpoints.common.recordTypes)
  }
  file(
    file: AttachmentFile,
    isOpen: boolean = false,
    saveAs: boolean = false,
    inside: boolean = false
  ) {
    const endpoint = endpoints.common.downloadFile(file.fileSequence)
    return reqFile(endpoint, file, isOpen, saveAs, inside)
  }
  downloadableFile(filePath: string) {
    return reqDownloadableFile(filePath)
  }
  getKainosMessage(params: URLSearchParams) {
    return reqGET(endpoints.common.kainosMessage, params, null, false)
  }
  getGroups() {
    return reqGET(endpoints.common.groups)
  }
  createFollowUp(params: FollowUpParams) {
    return reqPOST(endpoints.common.followUp, params)
  }
  updateFollowUp(params: FollowUpParams, taskIdSequence: number, emailIdSequence: number) {
    return reqPUT(`${endpoints.common.taskManagement}/${taskIdSequence}/${emailIdSequence}`, params)
  }
  getTaskManageDetail(taskIdSequence: number, emailIdSequence: number) {
    return reqGET(`${endpoints.common.taskManagement}/${taskIdSequence}/${emailIdSequence}`)
  }
  getPicUsers(params: { emailGroupCode: string; page: number; size: number }) {
    return reqGET(endpoints.common.picUser, params)
  }
  getReport() {
    return reqFile(endpoints.common.report, null, true, false)
  }
  getAddressBookGroups = (params: { findAll: boolean; keyword: string }) => {
    return reqGET(endpoints.common.addressBookGroups, params)
  }
  getSupplyItems() {
    return reqGET(endpoints.common.supplyItems)
  }
  getPortListV2(params: NationCodeParams) {
    return reqGET(endpoints.common.portCodesV2, params)
  }
  getSupplierListV2(params: NationCodeParams) {
    return reqGET(endpoints.common.suppliersV2, params)
  }

  getAdminConfiguration(moduleCode: string) {
    return reqGET(`${endpoints.common.adminConfiguration}/${moduleCode}`)
  }
  getNotices(params: getNoticeParameter) {
    return reqGET(endpoints.boardManagement.notice, params)
  }
  getLastestInfoQuotation(params: getDashboardParams) {
    return reqGET(endpoints.epro.dashboard.lastestInfo.quotation, params)
  }
  getLastestInfoOthers(params: getDashboardParams) {
    return reqGET(endpoints.epro.dashboard.lastestInfo.others, params)
  }
  getNewEbid(params: getDashboardParams) {
    return reqGET(endpoints.epro.dashboard.newEBid, params)
  }
  getProgressCount(params: getDashboardParams) {
    return reqGET(endpoints.epro.dashboard.progressCount, params)
  }
}
